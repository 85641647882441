import React from 'react';
import {
  Button,
  HStack,
  Avatar,
  Box,
  MenuButton,
  MenuItem,
  Menu,
  MenuList,
  Divider,
  AvatarBadge,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import { extractImage } from '../../utils/extractImage';
import NextLink from '../NextLink';
import NotificationBadge, { extractNtfSource } from '../NotificationBadge';
import { imageSizes } from '../../common/constants';

const AccountHeaderDesktop = ({
  logout,
  onRegister,
  onLogin,
  data,
  executorNtf,
  allNtf,
  ordererNtf,
  profileMenuItems,
}) => {
  const { t } = useTranslation(['common']);
  if (data && data.me) {
    return (
      <HStack spacing={2.5} alignItems='center' data-testid='desktop'>
        <Box
          ml={2.5}
          fontWeight='semibold'
          fontStyle='normal'
          lineHeight='20px'
          letterSpacing='-0.02em'
          maxW={50}
          display={{ sm: 'none', lg: 'block' }}
          whiteSpace='nowrap'
          overflow='hidden'
          textOverflow='ellipsis'
        >
          {data.me.name}
        </Box>
        <Menu placement='bottom-end'>
          <MenuButton w={8} h={8} data-testid='avatarOpenMenu'>
            <Avatar
              cursor='pointer'
              size='sm'
              src={extractImage(data?.profile?.image, imageSizes.XS)}
            >
              {allNtf ? <AvatarBadge boxSize={4} bg='red.500' /> : null}
            </Avatar>
          </MenuButton>
          <MenuList mt={4} p={0}>
            {profileMenuItems.map((item, index) => (
              <NextLink href={item.to} key={index}>
                <MenuItem dataTestId={item.id}>
                  {t(`menu.${item.name}`)}
                  <NotificationBadge
                    c={
                      extractNtfSource(item.type, { executorNtf, ordererNtf })
                        ?.length?.ntfLength
                    }
                  />
                </MenuItem>
              </NextLink>
            ))}
            <Divider size='ls' />
            <MenuItem
              onClick={() => {
                logout();
              }}
              data-testid='logout'
            >
              {t('button.logout')}
            </MenuItem>
          </MenuList>
        </Menu>
      </HStack>
    );
  }
  return (
    <>
      <Button
        data-testid='login'
        color='typography.primary'
        variant='link'
        mr={[0, 0, 0, 5]}
        outline='none'
        onClick={onLogin}
      >
        {t('button.log-in1')}
      </Button>
      <Button
        variant='secondary'
        display={['none', 'none', 'none', 'flex']}
        onClick={onRegister}
        name='registrationButton'
      >
        {t('button.signup1')}
      </Button>
    </>
  );
};

AccountHeaderDesktop.propTypes = {
  logout: PropTypes.func,
  onRegister: PropTypes.func,
  onLogin: PropTypes.func,
  data: PropTypes.object,
};

export default AccountHeaderDesktop;
