import React from 'react';
import { Badge } from '@chakra-ui/react';
import { tabKeys, subTabKeys } from '../ProfileTab/common';
import { statuses } from '../../store/notification/constants';

const { orders, tasks } = tabKeys;

/**
 * @param {String} type map to type OPEN || IN_PROGRESS || DONE
 *
 * Extract all events for particular entity and group them by type
 */
export const extractNtfType = source => {
  switch (source) {
    case subTabKeys.openOrders:
    case subTabKeys.openTasks:
      return statuses.OPEN;
    case subTabKeys.duringOrders:
    case subTabKeys.duringTasks:
      return statuses.IN_PROGRESS;
    case subTabKeys.closedOrders:
    case subTabKeys.closedTasks:
      return statuses.DONE;
    default:
      return source;
  }
};

/**
 * @param {String} type type corresponding to one of the notification sources
 * @param {object} sources Notification sources
 * @param {object} sources.executorNtf executor notification source
 * @param {object} sources.ordererNtf orderer notification source
 *
 * Extract all events for particular entity and group them by type
 */
export const extractNtfSource = (type, { executorNtf, ordererNtf }) => {
  switch (type) {
    case tasks:
    case subTabKeys.duringTasks:
    case subTabKeys.openTasks:
    case subTabKeys.closedTasks:
      return executorNtf;
    case orders:
    case subTabKeys.closedOrders:
    case subTabKeys.duringOrders:
    case subTabKeys.openOrders:
      return ordererNtf;
    default:
      return {};
  }
};

const NotificationBadge = ({ c, ...other }) => {
  const count = c || 0;
  return count ? (
    <Badge
      borderRadius='9999px'
      ml='10px'
      pr='6px'
      variant='solid'
      colorScheme='red'
      {...other}
    >
      +{count}
    </Badge>
  ) : null;
};

export default NotificationBadge;
